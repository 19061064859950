<template>
  <div>
    <div class="form-section">
      <b-row>
        <b-col cols="12">
          <!-- Project -->
          <ValidationProvider rules="required" name="status" v-slot="{ valid, errors }">
            <b-form-group
              :label="$t('general.project')"
              label-for="status"
              :label-class="{ 'is-invalid': errors[0] }"
              :invalid-feedback="errors[0]"
            >
              <div class="select-wrapper">
                <b-form-select required v-model="form.project_id" :state="errors[0] ? false : valid ? true : null">
                  <option value="null">{{ $t('forms.placeholders.select') }}</option>
                  <option v-for="project in projects" :key="project.id" :value="project.id">{{ project.name }}</option>
                </b-form-select>
              </div>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col cols="12">
          <!-- Category -->
          <ValidationProvider rules="required" name="status" v-slot="{ valid, errors }">
            <b-form-group
              :label="$t('general.category')"
              label-for="status"
              :label-class="{ 'is-invalid': errors[0] }"
              :invalid-feedback="errors[0]"
            >
              <div class="select-wrapper">
                <b-form-select
                  required
                  v-model="form.category_id"
                  :state="errors[0] ? false : valid ? true : null"
                  @change="onCategorySelected"
                >
                  <option value="null">{{ $t('forms.placeholders.select') }}</option>
                  <option v-for="categories in categories" :key="categories.id" :value="categories.id">{{ categories.name }}</option>
                </b-form-select>
              </div>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col cols="12">
          <!-- File name -->
          <ValidationProvider rules="required" name="file_name" v-slot="{ valid, errors }">
            <b-form-group
              :label="$t('general.fileName')"
              label-for="file_name"
              :label-class="{ 'is-invalid': errors[0] }"
              :invalid-feedback="errors[0]"
            >
              <b-form-input v-model="form.name" :state="errors[0] ? false : valid ? true : null"> </b-form-input>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col cols="12">
          <!-- File description -->
          <ValidationProvider rules="required" name="file_description" v-slot="{ valid, errors }">
            <b-form-group
              :label="$t('general.addDescription')"
              label-for="file_description"
              :label-class="{ 'is-invalid': errors[0] }"
              :invalid-feedback="errors[0]"
            >
              <b-form-textarea v-model="form.description" :state="errors[0] ? false : valid ? true : null"></b-form-textarea>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col cols="12">
          <!-- File tags -->
          <ValidationProvider rules="required" name="file_tags" v-slot="{ valid, errors }">
            <b-form-group
              :label="$t('general.addTags')"
              label-for="file_tags"
              :label-class="{ 'is-invalid': errors[0] }"
              :invalid-feedback="errors[0]"
            >
              <b-form-tags
                remove-on-delete
                :placeholder="$t('forms.placeholders.messageTags')"
                :duplicateTagText="$t('forms.placeholders.duplicateTag')"
                v-model="form.tags"
                :state="errors[0] ? false : valid ? true : null"
              ></b-form-tags>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col cols="12">
          <!-- Preview file -->
          <ValidationProvider rules="required" name="preview_file" v-slot="{ valid, errors }">
            <b-form-group
              :label="$t('general.previewFile') + (' (2 MB)')"
              label-for="preview_file"
              :label-class="{ 'is-invalid': errors[0] }"
              :invalid-feedback="errors[0]"
            >
              <b-form-file
                v-model="form.preview_file"
                accept="image/jpeg, image/png"
                :placeholder="previewFileName"
              ></b-form-file>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col cols="12">
          <!-- Files uploader -->
          <ValidationProvider rules="required" name="files" vid="files" v-slot="{ errors }">
            <b-form-group :label="$t('general.uploadFile')" :class="{ 'is-invalid': errors[0] }">
              <file-dropzone-input
                v-model="selectedFiles"
                :isInvalid="errors[0]"
                @fileDataAdded="onFileDataAdded"
                @fileAdded="onFileAdded"
                :category_id="form.category_id"
                ref="fileDropzoneInput"
              ></file-dropzone-input>
              <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
        <b-col cols="12" v-if="selectedFiles">
          <!-- File list-->
          <slot>
            <b-table responsive :fields="tableColumns" :items="fileList" class="common-table-container">
              <template v-slot:cell(actions)="data">
                <b-link @click="removeFile(data.item)"><i class="fa fa-trash"></i></b-link>
              </template>
            </b-table>
          </slot>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import i18n from '@/i18n';
import { ValidationProvider } from 'vee-validate';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      selectedFiles: [], // Archivos seleccionados para subir
      fileList: [], // Se usa para ver archivos en interfaz
      tableColumns: [
        { key: 'name', label: i18n.t('general.fileName') },
        { key: 'extension', label: i18n.t('general.format') },
        { key: 'actions', label: i18n.t('general.actions') },
      ],
    };
  },
  props: {
    form: Object,
  },
  components: {
    ValidationProvider,
    FileDropzoneInput: () => import('@/components/Forms/FileDropzoneInput/FileDropzoneInput.vue'),
  },
  methods: {
    onFileDataAdded(data) {
      this.fileList.push(data);
    },
    onFileAdded(file) {
      this.selectedFiles.push(file);
      this.$emit('filesAdded', this.selectedFiles);
    },
    onCategorySelected() {
      this.$refs.fileDropzoneInput.getCategory();
    },
    removeFile(file) {
      const index = this.fileList.indexOf(file);
      if (index > -1) {
        this.fileList.splice(index, 1);
        this.selectedFiles.splice(index, 1);
      }
    },
  },
  computed: {
    ...mapGetters({
      categories: 'common/categories',
      projects: 'common/projects',
    }),
  },
};
</script>
